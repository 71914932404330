class modelImageCarousel {

    constructor(data){

        this.id=data.id
        this.type=data.titre
        this.text=data.text
        this.image=data.image
    }
}

export default modelImageCarousel