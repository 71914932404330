class TeknoModel{

    constructor(data){
       
        this.id=data.id
        this.titre=data.titre
        this.l1=data.l1
        this.l2=data.l2
        this.l3=data.l3
        this.l4=data.l4
        this.l5=data.l5
        this.l6=data.l6



    }
}

export default TeknoModel